import React, { Fragment, useState } from 'react';
import { useForm, Controller } from "react-hook-form";
import {
  Container, Row, Col, Input, InputGroup,
  InputGroupText,
  Button, Table,
  Card, CardBody, CardTitle, CardText, CardSubtitle
} from "reactstrap";

import './App.css';

const App = () => {

  const [loading, setLoading] = useState(true);
  const [calculations, setCalculations] = useState(true);
  const [endingBalance, setEndingBalance] = useState(0);
  const [endingGain, setEndingGain] = useState(0);

  let calcArray = []

  const currencyFormat = (num) => {
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  const { register, handleSubmit, control, formState: { errors }, reset } = useForm();

  const onSubmit = (data) => {

    console.log(data)

    const startingBalance = Number(data.investmentValue)
    const periods = Number(data.investmentDays)
    const percentage = Number(data.investmentRate)

    console.log(startingBalance)
    console.log(periods)
    console.log(percentage)

    let intialValues = {
      sBalance: startingBalance,
      gain: percentage
    }

    let totalI = 0.0;
    for (let i = 0; i < periods; ++i) {

      let interest = percentage * (startingBalance + totalI) / 100;
      totalI += interest;

      let delta = Number.parseFloat(interest).toFixed(2)
      let amount = Number.parseFloat(totalI + startingBalance).toFixed(2)
      let gainP = Number.parseFloat((totalI / startingBalance) * 100).toFixed(2)

      let calObj = {
        sBalance: Number(amount),
        gain: Number(gainP)
      }

      calcArray.push(calObj)
    }

    calcArray.splice(0, 0, intialValues)

    let itemsCalculated = calcArray
    let itemsCalculatedCopy = calcArray

    let copiedItemArray = []
    itemsCalculatedCopy.map((item, idx) => {
      let copiedItem = {
        sBalance: itemsCalculated[idx]?.sBalance,
        gain: itemsCalculated[idx + 1]?.gain,
        eBalance: itemsCalculated[idx + 1]?.sBalance,
      }

      copiedItemArray.push(copiedItem)
    })

    copiedItemArray.pop()
    let endingBalance = copiedItemArray[copiedItemArray.length - 1].eBalance
    let endingGain = copiedItemArray[copiedItemArray.length - 1].gain

    console.log(copiedItemArray)
    setEndingBalance(endingBalance)
    setEndingGain(endingGain)
    setCalculations(copiedItemArray)

    setLoading(false)

  }

  return (
    <Fragment>

      <Container fluid={false} className='mt-4'>

        <h1 style={{ fontWeight: 'bold' }} className='mt-5 mb-4'>Interés Compuesto</h1>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">

          <Row>
            <Col className='mt-6 mb-2' style={{ fontWeight: "bold" }}>Monto de Inversión:</Col>

            <Controller
              name="investmentValue"
              control={control}
              defaultValue={''}
              autoComplete={'' + Math.random()}
              role="presentation"
              rules={{ required: 'Descripción del Link es requerido' }}
              render={({ field }) =>
                <InputGroup>
                  <Input
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    {...field}
                    type="number"
                    min={0}
                  />
                  <InputGroupText>
                    USD
                  </InputGroupText>
                </InputGroup>
              }
            />
            {errors.investmentValue?.type === 'required' && <span className="srv-validation-message mb-3"> Campo Requerido</span>}
          </Row>

          <Row>
            <Col className='my-2' style={{ fontWeight: "bold" }}>Tiempo de Inversión:</Col>

            <Controller
              name="investmentDays"
              control={control}
              defaultValue={''}
              autoComplete={'' + Math.random()}
              rules={{ required: 'Descripción del Link es requerido' }}
              render={({ field }) =>
                <InputGroup>
                  <Input
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    {...field}
                    type="number"
                    min={0}
                  />
                  <InputGroupText>
                    Dias
                  </InputGroupText>
                </InputGroup>
              }
            />
            {errors.investmentDays?.type === 'required' && <span className="srv-validation-message mb-3"> Campo Requerido</span>}

          </Row>

          <Row>
            <Col className='my-2' style={{ fontWeight: "bold" }}>Porcentaje de Ganancia:</Col>

            <Controller
              name="investmentRate"
              defaultValue={''}
              control={control}
              autoComplete={'' + Math.random()}
              rules={{ required: true }}
              render={({ field }) =>
                <InputGroup>
                  <Input
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    {...field}
                    type="number"
                    min={0}
                  />
                  <InputGroupText>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;%
                  </InputGroupText>
                </InputGroup>
              }
            />
            {errors.investmentRate?.type === 'required' && <span className="srv-validation-message mb-3"> Campo Requerido</span>}

          </Row>

          <Row>
            <Col className='mt-4 text-center'>
              <Button
                style={{ fontWeight: 'bold' }}
                type="submit"
              >
                Calcular Ganancia
              </Button>
            </Col>
          </Row>

        </form>

        <Row className="mt-4">
          {!loading &&
            <Fragment>
              <Col sm={{ size: 2, offset: 4 }}>

                <Card>
                  <CardBody>
                    <CardTitle tag="h5" style={{ fontWeight: 'bold' }}>
                      Balance Final
                    </CardTitle>

                    <CardText>
                      <span style={{ fontWeight: 'bold' }}> {currencyFormat(endingBalance)}</span>
                    </CardText>

                  </CardBody>
                </Card>

              </Col>

              <Col lg={2}>

                <Card
                >
                  <CardBody>
                    <CardTitle tag="h5" style={{ fontWeight: 'bold' }}>
                      Ganancia Total
                    </CardTitle>

                    <CardText>
                      <span style={{ fontWeight: 'bold' }}> {endingGain} %</span>
                    </CardText>

                  </CardBody>
                </Card>

              </Col>
            </Fragment>
          }
        </Row>

        <Row>

          {!loading &&
            <Table
              bordered
              hover
              responsive
              striped
              className='mt-4'
            >
              <thead>
                <tr>
                  <th>
                  </th>
                  <th>
                    Balance Inicial
                  </th>
                  <th>
                    Ganancia
                  </th>
                  <th>
                    Balance Final
                  </th>
                </tr>
              </thead>
              <tbody>

                {calculations.length > 0 && calculations.map((item, idx) => {
                  return (
                    <tr key={idx}>
                      <th scope="row">{idx + 1}</th>
                      <td>{currencyFormat(item.sBalance)}</td>
                      <td>{item.gain} %</td>
                      <td>{currencyFormat(item.eBalance)}</td>
                    </tr>
                  )
                })

                }

              </tbody>
            </Table>
          }
        </Row>

        <Row>
          <Col>
            <h3 className='mb-3' style={{ fontWeight: 'bold' }}>Preguntas Frecuentes</h3>
            <h5 className='mb-3'>¿Qué es una calculadora de interés compuesto?</h5>
            <p>
              Una calculadora de interés compuesto es útil para simular cómo la capitalización del capital inicial y las
              operaciones rentables, con un porcentaje de ganancia establecido, puede hacer que una cuenta crezca con el tiempo.
              <br />
              Funciona simulando la capitalización y la reinversión del mismo porcentaje de ganancia elegido del capital total de la cuenta.
            </p>
          </Col>
        </Row>

        <Row>
          <Col>
            <h5 className='mb-3'>Conceptos Basicos</h5>
            <p>
              <b>Saldo inicial:</b> Esto representa el capital inicial de su cuenta.
              <br />
              <b>Número de periodos:</b> En el campo de tiempo de inversion, es la cantidad de dias en la cual su inversion va generando ganancias.
              <br />
              <b>Porcentaje de Ganancia:</b> ¡El campo más importante de la calculadora! Este campo se puede utilizar para simular el porcentaje de ganancia por cualquier período de capitalización.
            </p>
          </Col>
        </Row>

      </Container>
    </Fragment>
  );
}

export default App;
